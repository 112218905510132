import {defineStore} from 'pinia'
import {AXIOS} from "../../http-commons";

export const useTasks = defineStore('tasksStore', {
  id: 'tasks',
  state: () => ({
    tasks: [],
    loading: false
  }),
  actions: {
    addTask(task) {
      this.tasks.unshift(task);
      this.tasks.sort((a, b) => (a.dueTime > b.dueTime) ? -1 : 1);
    },
    patchTask(task) {
      let elIndex = this.tasks.findIndex((elem => elem.taskId === task.taskId));
      this.tasks[elIndex] = task;
      this.tasks.sort((a, b) => (a.dueTime > b.dueTime) ? -1 : 1);
    },
    deleteTask(taskId) {
      let elIndex = this.tasks.findIndex((elem => elem.taskId === taskId));
      this.tasks.splice(elIndex, 1);
    },
    deleteMultipleTasks(taskList) {
      for (let i = 0; i < taskList.length; i++) {
        let elIndex = this.tasks.findIndex((elem => elem.taskId === taskList[i].taskId));
        this.tasks.splice(elIndex, 1);
      }
    },
    populateTasks() {
      this.loading = true;
      let userId = JSON.parse(localStorage.getItem('user')).id
      let params = new URLSearchParams();
      params.append('userId', userId)
      AXIOS.get('/api/getAllTasks', {params})
        .then(response => {
          this.tasks = response.data;
          this.loading = false;
          console.log("finished loading tasks",this.tasks)
        })
      return this.tasks;
    }
  }
})
