<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline">
          <h4>
            {{$t('dialogs.delete-confirmation.confirm-text')}} {{this.objectName}} <strong
            v-if="this.objectName==='payer'">{{this.payer.payerName}}</strong>
            <strong v-if="this.objectName==='task'"><br>{{this.task.taskName}}</strong>
            <strong v-if="this.objectName==='manager'"><br>{{this.manager.surname}}</strong>
            <strong v-if="this.objectName==='order'"><br>{{this.order.orderCode}}</strong>
            <strong v-if="this.objectName==='agency'"><br>{{this.agency.agencyName}}</strong>
            <strong v-if="this.objectName==='file'"><br>{{this.file.fileName}}</strong>?
          </h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-col md="12">
            <h4 style="color: red">{{$t('dialogs.delete-confirmation.warning-text')}}</h4>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined text color="red" @click="$emit('delete-object')">
            {{$t('buttons.delete')}}
          </v-btn>
          <v-btn color="primary" @click="$emit('close-dialog')">
            {{$t('buttons.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ['objectName', 'open-dialog', 'payer', 'file', 'task','manager','order','agency'],
    name: "DeleteConfirmationDialog",
  }
</script>
