<template>
  <v-container fluid>
    <CreateCalendarTaskDialog v-bind:open-dialog="openDialog" v-on:close-dialog="openDialog=false"
                              v-on:save-record="saveRecord($event)" :action="action"
                              v-on:create-record="createRecord($event)" :record="record"
                              :newTask="newTask"></CreateCalendarTaskDialog>
    <DeleteConfirmationDialog :object-name="'task'" :task="task"
                              v-on:close-dialog="deleteDialog=false" v-bind:open-dialog="deleteDialog"
                              v-on:delete-object="deleteObject"></DeleteConfirmationDialog>
    <base-material-card color="primary" class="px-5 py-3">
      <template v-slot:heading>
        <v-row class="ma-auto">
          <v-col md="6" sm="8">
            <v-icon left color="white">mdi-calendar-check-outline</v-icon>
            {{$t('tables.all-tasks')}}
            <v-badge offset-x="-5" offset-y="-5" color="blue" :content="tasks.length?tasks.length:'0'"></v-badge>
          </v-col>
          <v-col md="6" sm="4">
            <v-btn small color="white" outlined style="float: right" @click="createItem">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </template>

      <v-card-title>
        <v-row class="ma-auto">
          <v-col cols="12" md="4" sm="12">
            <v-text-field v-model="search" append-icon="mdi-magnify"
                          :label="$t('search')" single-line hide-details>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-spacer></v-spacer>
      <v-data-table
        dense
        single-expand
        item-key="taskId"
        :loading="loading"
        multi-sort
        :headers="headers"
        :items="tasks"
        :search="search"
        show-expand
        :expanded.sync="expanded"
        :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]
              }">
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" @click="deleteItem(item)">
            mdi-trash-can-outline
          </v-icon>
        </template>

        <!--        <template v-slot:item.order.orderCode="{ item }">-->
        <!--          <router-link :to="{ name: 'Order Card', params: { orderId: item.order.orderId}}" v-if="item.order!==null">-->
        <!--            {{item.order.orderCode}}-->
        <!--          </router-link>-->
        <!--        </template>-->
        <!--        <template v-slot:item.order.agency.agencyName="{ item }">-->
        <!--          <router-link :to="{ name: 'Agency Card', params: { agencyId: item.order.agency.agencyId}}"-->
        <!--                       v-if="item.order!==null">-->
        <!--            {{item.order.agency.agencyName}}-->
        <!--          </router-link>-->
        <!--        </template>-->
        <template v-slot:item.taskName="{ item }">
          <router-link :to="{ path: '/task/' + item.taskId}" class="no-underline-link">
            {{item.taskName}}
          </router-link>
        </template>
        <template v-slot:item.dueTime="{ item }">
          <span :key="parseTime(item.dueTime)">{{parseTime(item.dueTime)}}</span>
        </template>
        <template v-slot:item.startTime="{ item }">
          <span :key="parseTime(item.startTime)">{{parseTime(item.startTime)}}</span>
        </template>
        <template v-slot:item.createdTime="{ item }">
          <span :key="parseTime(item.createdTime)">{{parseTime(item.createdTime)}}</span>
        </template>
        <template v-slot:item.taskActive="{ item }">
          <v-switch v-model="item.taskActive" inset v-on:change="activateItem(item.taskId, item.taskActive)">
          </v-switch>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length/2">
            <v-simple-table dense>
              <tr>
                <td>
                  Created:
                </td>
                <td>
                  <h5>{{ parseTime(item.createdTime) }}</h5>
                </td>
                <td>
                  Modified:
                </td>
                <td>
                  <h5>{{ parseTime(item.modifiedTime) }}</h5>
                </td>
              </tr>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import CreateObjectButton from "../../components/buttons/CreateObjectButton";
  import CreateCalendarTaskDialog from "./CreateCalendarTaskDialog";
  import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
  import {mapState} from "pinia";
  import {useTasks} from "../../stores/tasksStore";

  const tasksStore = useTasks()

  let moment = require('moment');

  export default {
    name: "Tasks",
    components: {CreateObjectButton, CreateCalendarTaskDialog, DeleteConfirmationDialog},
    data() {
      return {
        task: [],
        objectName: 'Task',
        deleteDialog: false,
        action: '',
        openDialog: false,
        imgName: '',
        record: null,
        newTask: [],
        expanded: [],
        object: 'Task',
        search: '',
        headers: [
          {text: this.$t('tables.startTime'), value: 'startTime', class: "primary--text"},
          {text: this.$t('tables.dueTime'), value: 'dueTime', class: "primary--text"},
          {
            text: this.$t('tables.taskName'),
            align: 'start',
            sortable: true,
            value: 'taskName',
            class: "primary--text",
          },
          {text: this.$t('tables.description'), value: 'description', class: "primary--text"},
          // {text: this.$t('tables.agency'), value: 'order.agency.agencyName', class: "primary--text"},
          {text: this.$t('tables.taskReady'), value: 'taskActive', class: "primary--text"},
          {text: this.$t('tables.actions'), value: 'actions', class: "primary--text"},
        ],
      }
    },
    methods: {
      updateNeededCheck() {
        if (!this.tasks.length) {
          tasksStore.populateTasks()
        }
      },
      saveRecord(record) {
        let action = 'update';
        this.openDialog = false
        let format = "YYYY-MM-DDTHH:mm:ss";
        let startTime = moment(record.startTime).format(format);
        let dueTime = moment(record.dueTime).format(format);
        let params = new URLSearchParams();
        params.append('taskId', record.taskId);
        params.append('taskName', record.taskName);
        params.append('description', record.description);
        params.append('startTime', startTime);
        params.append('dueTime', dueTime);
        params.append('taskActive', record.taskActive);
        AXIOS.put(`/api/updateTask`, params)
          .then(response => {
            tasksStore.patchTask(response.data);
            this.userNotification(response.data, action, this.objectName);
          })

      },
      createRecord(record) {
        let action = 'create';
        this.openDialog = false;
        let format = "yyyy-MM-DDTHH:mm:ss";
        let startTime = moment(record.startTime).format(format);
        let dueTime = moment(record.dueTime).format(format);
        let params = new URLSearchParams();
        params.append('taskName', record.taskName);
        params.append('description', record.description);
        params.append('dueTime', dueTime);
        params.append('startTime', startTime);
        params.append('userId', this.currentUser.id);
        AXIOS.post(`/api/createTask`, params)
          .then(response => {
            tasksStore.addTask(response.data);
            this.userNotification(response.data, action, this.objectName);
          })
      },
      createItem() {
        this.openDialog = true
        this.action = 'create'
      },
      deleteItem(item) {
        this.deleteDialog = true
        this.task = item
      },
      editItem(item) {
        this.openDialog = true
        this.record = item
        this.action = 'edit'
      },
      activateItem(taskId, active) {
        let action = active ? 'activate' : 'deactivate';
        let params = new URLSearchParams();
        params.append('taskActive', active);
        params.append('taskId', taskId);
        AXIOS.put(`/api/activateTask/`, params)
          .then(response => {
            this.response = response.data;
            this.userNotification(response, action, this.objectName);
          })
      },
      parseTime(time) {
        moment.updateLocale(moment.locale(), {invalidDate: "Empty date"})
        return moment(time).format("DD.MM.YYYY HH:mm");
      },
      deleteObject() {
        this.deleteDialog = false;
        let action = 'delete'
        let params = new URLSearchParams();
        params.append('taskId', this.task.taskId);
        AXIOS.delete('/api/deleteTask', {params})
          .then(response => {
            tasksStore.deleteTask(response.data)
            this.userNotification(response, action, this.objectName);
          })
      },
    },
    computed: {
      ...mapState(useTasks, ['tasks', 'loading']),
    },
    created() {
      this.updateNeededCheck();
    },
  }
</script>

<style scoped>
  .no-underline-link {
    text-decoration: none;
  }
</style>
