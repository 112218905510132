var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.create($event)}}},[_c('v-card',[_c('v-container',[(_vm.action==='edit')?_c('h3',[_vm._v(_vm._s(_vm.parseTime))]):_vm._e(),_c('v-img',{attrs:{"src":require(("@/assets/UI-images/" + _vm.imgName)),"height":"100px"}}),_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.action==='create'?_vm.$t('task-page.new'):_vm.$t('task-page.edit'))+" "+_vm._s(_vm.$t('task-page.task')))])]),_c('v-divider'),_c('v-card-text',[(_vm.action==='create')?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('task-page.task-name'),"clearable":""},model:{value:(_vm.newTask.taskName),callback:function ($$v) {_vm.$set(_vm.newTask, "taskName", $$v)},expression:"newTask.taskName"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-datetime-picker',{attrs:{"label":_vm.$t('task-page.start-time'),"timePickerProps":_vm.timePickerProps,"date-picker-props":_vm.datePickerProps,"text-field-props":_vm.textFieldProps},model:{value:(_vm.newTask.startTime),callback:function ($$v) {_vm.$set(_vm.newTask, "startTime", $$v)},expression:"newTask.startTime"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-datetime-picker',{attrs:{"label":_vm.$t('task-page.due-time'),"timePickerProps":_vm.timePickerProps,"date-picker-props":_vm.datePickerProps,"text-field-props":_vm.textFieldProps},model:{value:(_vm.newTask.dueTime),callback:function ($$v) {_vm.$set(_vm.newTask, "dueTime", $$v)},expression:"newTask.dueTime"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.newTask.dueTime!=='' && _vm.newTask.startTime>_vm.newTask.dueTime)?_c('v-alert',{attrs:{"dense":"","outlined":"","border":"left","text":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('task-page.due-time-validation'))+" ")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('task-page.description'),"clearable":""},model:{value:(_vm.newTask.description),callback:function ($$v) {_vm.$set(_vm.newTask, "description", $$v)},expression:"newTask.description"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.action==='edit')?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('task-page.task-name'),"clearable":""},model:{value:(_vm.record.taskName),callback:function ($$v) {_vm.$set(_vm.record, "taskName", $$v)},expression:"record.taskName"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-datetime-picker',{attrs:{"label":_vm.$t('task-page.start-time'),"timePickerProps":_vm.timePickerProps,"date-picker-props":_vm.datePickerProps,"text-field-props":_vm.textFieldProps},model:{value:(_vm.record.startTime),callback:function ($$v) {_vm.$set(_vm.record, "startTime", $$v)},expression:"record.startTime"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-datetime-picker',{attrs:{"label":_vm.$t('task-page.due-time'),"timePickerProps":_vm.timePickerProps,"date-picker-props":_vm.datePickerProps,"text-field-props":_vm.textFieldProps},model:{value:(_vm.record.dueTime),callback:function ($$v) {_vm.$set(_vm.record, "dueTime", $$v)},expression:"record.dueTime"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.record.dueTime!=='' && _vm.record.startTime>_vm.record.dueTime)?_c('v-alert',{attrs:{"dense":"","outlined":"","border":"left","text":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('task-page.due-time-validation'))+" ")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('task-page.description'),"clearable":""},model:{value:(_vm.record.description),callback:function ($$v) {_vm.$set(_vm.record, "description", $$v)},expression:"record.description"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),(_vm.action==='edit')?_c('v-btn',{attrs:{"color":"primary","disabled":invalid||_vm.record.startTime>_vm.record.dueTime},on:{"click":function($event){return _vm.$emit('save-record',_vm.record)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")]):_vm._e(),(_vm.action==='create')?_c('v-btn',{attrs:{"disabled":invalid || _vm.newTask.startTime>_vm.newTask.dueTime,"color":"primary"},on:{"click":function($event){return _vm.$emit('create-record', _vm.newTask)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.create-task'))+" ")]):_vm._e()],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }