var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CreateCalendarTaskDialog',{attrs:{"open-dialog":_vm.openDialog,"action":_vm.action,"record":_vm.record,"newTask":_vm.newTask},on:{"close-dialog":function($event){_vm.openDialog=false},"save-record":function($event){return _vm.saveRecord($event)},"create-record":function($event){return _vm.createRecord($event)}}}),_c('DeleteConfirmationDialog',{attrs:{"object-name":'task',"task":_vm.task,"open-dialog":_vm.deleteDialog},on:{"close-dialog":function($event){_vm.deleteDialog=false},"delete-object":_vm.deleteObject}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"ma-auto"},[_c('v-col',{attrs:{"md":"6","sm":"8"}},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("mdi-calendar-check-outline")]),_vm._v(" "+_vm._s(_vm.$t('tables.all-tasks'))+" "),_c('v-badge',{attrs:{"offset-x":"-5","offset-y":"-5","color":"blue","content":_vm.tasks.length?_vm.tasks.length:'0'}})],1),_c('v-col',{attrs:{"md":"6","sm":"4"}},[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"small":"","color":"white","outlined":""},on:{"click":_vm.createItem}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-divider')]},proxy:true}])},[_c('v-card-title',[_c('v-row',{staticClass:"ma-auto"},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-spacer'),_c('v-data-table',{attrs:{"dense":"","single-expand":"","item-key":"taskId","loading":_vm.loading,"multi-sort":"","headers":_vm.headers,"items":_vm.tasks,"search":_vm.search,"show-expand":"","expanded":_vm.expanded,"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              itemsPerPageOptions: [ 5, 10, 15, 50, -1]
            }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-trash-can-outline ")])]}},{key:"item.taskName",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"no-underline-link",attrs:{"to":{ path: '/task/' + item.taskId}}},[_vm._v(" "+_vm._s(item.taskName)+" ")])]}},{key:"item.dueTime",fn:function(ref){
            var item = ref.item;
return [_c('span',{key:_vm.parseTime(item.dueTime)},[_vm._v(_vm._s(_vm.parseTime(item.dueTime)))])]}},{key:"item.startTime",fn:function(ref){
            var item = ref.item;
return [_c('span',{key:_vm.parseTime(item.startTime)},[_vm._v(_vm._s(_vm.parseTime(item.startTime)))])]}},{key:"item.createdTime",fn:function(ref){
            var item = ref.item;
return [_c('span',{key:_vm.parseTime(item.createdTime)},[_vm._v(_vm._s(_vm.parseTime(item.createdTime)))])]}},{key:"item.taskActive",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.activateItem(item.taskId, item.taskActive)}},model:{value:(item.taskActive),callback:function ($$v) {_vm.$set(item, "taskActive", $$v)},expression:"item.taskActive"}})]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length/2}},[_c('v-simple-table',{attrs:{"dense":""}},[_c('tr',[_c('td',[_vm._v(" Created: ")]),_c('td',[_c('h5',[_vm._v(_vm._s(_vm.parseTime(item.createdTime)))])]),_c('td',[_vm._v(" Modified: ")]),_c('td',[_c('h5',[_vm._v(_vm._s(_vm.parseTime(item.modifiedTime)))])])])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }