<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="create">
          <v-card>
            <v-container>
              <h3 v-if="action==='edit'">{{parseTime}}</h3>
              <v-img :src="require(`@/assets/UI-images/${imgName}`)" height="100px">
              </v-img>
              <v-card-title>
                <span class="headline">{{action==='create'?$t('task-page.new'):$t('task-page.edit')}} {{$t('task-page.task')}}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container v-if="action==='create'">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <v-text-field v-model="newTask.taskName" :label="$t('task-page.task-name')" clearable/>
                        <span class="red--text font-weight-light">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <v-datetime-picker v-model="newTask.startTime" :label="$t('task-page.start-time')"
                                           :timePickerProps="timePickerProps"
                                           :date-picker-props="datePickerProps" :text-field-props="textFieldProps">
                        </v-datetime-picker>
                        <span class="red--text font-weight-light">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <v-datetime-picker v-model="newTask.dueTime" :label="$t('task-page.due-time')"
                                           :timePickerProps="timePickerProps"
                                           :date-picker-props="datePickerProps" :text-field-props="textFieldProps">
                        </v-datetime-picker>
                        <span class="red--text font-weight-light">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                    <v-alert v-if="newTask.dueTime!=='' && newTask.startTime>newTask.dueTime"
                             dense outlined border="left" text type="error">
                      {{$t('task-page.due-time-validation')}}
                    </v-alert>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <v-text-field v-model="newTask.description" :label="$t('task-page.description')" clearable/>
                        <span class="red--text font-weight-light">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container v-if="action==='edit'">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <v-text-field v-model="record.taskName" :label="$t('task-page.task-name')" clearable/>
                        <span class="red--text font-weight-light">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <v-datetime-picker v-model="record.startTime" :label="$t('task-page.start-time')"
                                           :timePickerProps="timePickerProps"
                                           :date-picker-props="datePickerProps" :text-field-props="textFieldProps">
                        </v-datetime-picker>
                        <span class="red--text font-weight-light">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <v-datetime-picker v-model="record.dueTime" :label="$t('task-page.due-time')"
                                           :timePickerProps="timePickerProps"
                                           :date-picker-props="datePickerProps" :text-field-props="textFieldProps">
                        </v-datetime-picker>
                        <span class="red--text font-weight-light">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                    <v-alert v-if="record.dueTime!=='' && record.startTime>record.dueTime"
                             dense outlined border="left" text type="error">
                      {{$t('task-page.due-time-validation')}}
                    </v-alert>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <v-text-field
                          v-model="record.description" :label="$t('task-page.description')" clearable/>
                        <span class="red--text font-weight-light">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="$emit('close-dialog')">
                  {{$t('buttons.cancel')}}
                </v-btn>
                <v-btn v-if="action==='edit'" color="primary" v-on:click="$emit('save-record',record)"
                       :disabled="invalid||record.startTime>record.dueTime">
                  {{$t('buttons.save')}}
                </v-btn>
                <v-btn v-if="action==='create'"
                       :disabled="invalid || newTask.startTime>newTask.dueTime"
                       color="primary" v-on:click="$emit('create-record', newTask)">
                  {{$t('buttons.create-task')}}
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
  </v-row>
</template>

<script>
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {required} from 'vee-validate/dist/rules';

  let moment = require('moment');

  extend('required', {
    ...required,
    message: 'The field is required'
  });
  export default {
    props: ['openDialog', 'newTask', 'action', 'record'],
    components: {ValidationProvider, ValidationObserver},
    name: "CreateCalendarTaskDialog",
    data: () => ({
      startTime: '',
      dueTime: '',
      imgName: 'createNewTask.jpeg',
      timePickerProps: {
        format: "24hr"
      },
      datePickerProps: {
        firstDayOfWeek: 1
      },
      textFieldProps: {
        prependIcon: 'mdi-clock-time-four-outline'
      },
    }),
    computed: {
      parseTime() {
        let format = 'YYYY-MM-DD HH:mm';
        this.record.startTime = moment(this.record.startTime).format(format);
        this.record.dueTime = moment(this.record.dueTime).format(format);
        return this.startTime
      },
    }
  }
</script>
